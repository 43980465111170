import { render, staticRenderFns } from "./customerDashboard.vue?vue&type=template&id=f051a45c&scoped=true&"
import script from "./customerDashboard.vue?vue&type=script&lang=js&"
export * from "./customerDashboard.vue?vue&type=script&lang=js&"
import style0 from "./customerDashboard.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./customerDashboard.vue?vue&type=style&index=1&id=f051a45c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f051a45c",
  null
  
)

export default component.exports